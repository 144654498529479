import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";

const JoinUs = () => {
    const seo = {
        metaDesc: 'Join Us - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Join Us - Inventiv.org" canonical='/join-us' seo={seo} />
            <div class="sub-banner overview-bgi">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1>Join Us</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <p class="sec-clr f-16">
                        We’re thrilled to have you join our community of inventors and innovators. Whether you’re a seasoned inventor with multiple patents under your belt or someone who’s just beginning to explore the world of innovation, Inventiv.org is here to support and guide you every step of the way. So, whether you’re curious about patent filing, prototyping techniques, or simply seeking inspiration, you’ve come to the right place. Inventiv.org is your partner in turning your inventive dreams into reality.
                        <br /><br />
                        At Inventiv.org, we understand that turning an idea into a reality can be a challenging and sometimes overwhelming process. That’s why we’ve built a platform dedicated to providing you with the educational materials and software tools you need to cross that bridge with confidence.
                        <br /><br />
                        Our team of experts has curated a wealth of resources designed to inspire creativity, enhance your inventing skills, and help you overcome any obstacles you may encounter along the way. From in-depth guides and tutorials to interactive software solutions, we’ve got you covered.
                        <br /><br />
                        But Inventiv.org is more than just a platform – it’s a vibrant community of like-minded individuals who share your passion for innovation. Connect with fellow inventors, exchange ideas, and collaborate on exciting projects. The power of collective knowledge and collaboration knows no bounds.
                        <br /><br />
                        We believe that every idea has the potential to change the world, and we’re committed to helping you unleash your creativity and make a meaningful impact. Get ready to embark on an incredible journey of discovery, growth, and invention.
                        <br /><br />
                        Once again, welcome to Inventiv.org! We can’t wait to see what amazing innovations you’ll bring to life.
                        <br /><br />
                        Best regards,
                        <br /><br />
                        The Inventiv.org Team

                    </p>
                </div>
            </section>
        </Layout>
    )
};

export default JoinUs;